.custom-switch {
    margin-top: 2px;
  }
  
.custom-switch .react-switch-bg {
    width: 46px !important;
    height: 20px !important;
    margin-top: 1px !important;
  }

.custom-switch .react-switch-bg div:nth-child(2) {
    height: 0px !important;
    width: 0px !important;
}

.custom-switch .react-switch-bg div:first-child {
    height: 0px !important;
    width: 0px !important;
  }

.custom-switch .react-switch-handle {
    height: 20px !important;
    width: 19px !important;
  }